body {
  margin: 0;
}

@tailwind base;
@tailwind components;
@tailwind utilities;


.ant-spin-text {
  font-size: 14px !important;
  color: black !important;
  text-shadow: none !important;
}
.grecaptcha-badge {
  display: none !important; 
}